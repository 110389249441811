import styled, { keyframes } from "styled-components";
import { useState } from "react";
import { useCartContext } from "./context/CartContext";
import CartItem from "./components/CartItem";
import { NavLink } from "react-router-dom";
import { Button } from "./styles/Button";
import FormatPrice from "./Helpers/FormatPrice";
import axios from "axios";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

const cities = [
  "Karachi",
  "Lahore",
  "Islamabad",
  "Rawalpindi",
  "Faisalabad",
  "Multan",
  "Gujranwala",
  "Quetta",
  "Peshawar",
  "Hyderabad",
  "Sialkot",
  "Gujrat",
  "Bahawalpur",
  "Sargodha",
  "Sukkur",
  "Jhang",
  "Sheikhupura",
  "Mardan",
  "Larkana",
  "Kasur",
  "Abbottabad",
  "Murree",
  "Chakwal",
  "Sahiwal",
  "Vehari",
  "Okara",
  "Dera Ghazi Khan",
  "Muzaffarabad",
  "Mirpur",
  "Mingora",
  "Nowshera",
  "Mansehra",
  "Attock",
  "Bhakkar",
  "Rahim Yar Khan",
  "Toba Tek Singh",
  "Kohat",
  "Chiniot",
  "Swat",
  "Hafizabad",
  "Nankana Sahib",
  "Khushab",
  "Dera Ismail Khan",
  "Charsadda",
  "Mandi Bahauddin",
  "Jhelum",
  "Khuzdar",
  "Jacobabad",
  "Kandhkot",
  "Hub",
  "Turbat",
  "Zhob",
  "Gwadar",
  "Shikarpur",
  "Mirpur Khas",
  "Nawabshah",
  "Badin",
  "Thatta",
  "Hala",
  "Skardu",
  "Gilgit",
  "Chilas",
  "Hunza",
  "Khaplu",
  "Diamer",
  "Ghotki",
  "Umerkot",
  "Tando Adam",
  "Kambar",
  "Dadu",
  "Shahdadpur",
  "Tando Allahyar",
  "Moro",
  "Layyah",
  "Rajanpur",
  "Lodhran",
  "Narowal",
  "Muzafargarh",
  "Kharian",
  "Haripur",
  "Tank",
  "Chitral",
  "Parachinar",
  "Bannu",
  "Hangu",
  "Buner",
  "Shangla",
  "Malakand",
  "Timergara",
  "Upper Dir",
  "Lower Dir",
  "Ziarat",
  "Kalat",
  "Sibi",
  "Dalbandin",
  "Pasni",
  "Ormara",
  "Surab",
  "Panjgur",
  "Dera Allah Yar",
  "Usta Mohammad",
  "Mach",
  "Sui",
  "Gadani",
  "Jiwani",
  "Tando Muhammad Khan",
];

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Cart = () => {
  const { cart, clearCart, total_price, shipping_fee } = useCartContext();

  const [formData, setFormData] = useState({
    fullName: "",
    mobile: "",
    email: "",
    city: "",
    address: "",
  });
  const [errors, setErrors] = useState({});
  const [isOrderPlaced, setIsOrderPlaced] = useState(false); // State to manage success message

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.fullName.trim()) {
      errors.fullName = "Full Name is required";
    }

    if (
      !formData.email.trim() ||
      !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(
        formData.email.trim()
      )
    ) {
      errors.email = "Please enter a valid email address";
    }

    if (!formData.mobile.trim() || !/^03\d{9}$/.test(formData.mobile.trim())) {
      errors.mobile = "Please enter a valid Pakistani mobile number";
    }

    if (!formData.city.trim()) {
      errors.city = "City is required";
    }

    if (!formData.address.trim()) {
      errors.address = "Address is required";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const cartData = cart.map((item) => ({
          id: item.id,
          title: item.title,
          color: item.color,
          quantity: item.quantity,
          price: item.price,
        }));

        const orderData = {
          ...formData,
          cart: cartData,
          totalAmount: total_price + shipping_fee,
        };

        const api = axios.create({
          baseURL:
            "https://productapi-hamzaretroconsoles-express-iwuv.onrender.com/api/",
        });

        const response = await api.post("orders/save-order", {
          ...formData,
          cart,
          totalAmount: total_price + shipping_fee,
        });

        if (response.data.message) {
          setIsOrderPlaced(true);

          await Promise.all(
            cart.map((item) =>
              api.patch(`products/update-quantity/${item.id}`, {
                quantity: item.quantity,
              })
            )
          );

          clearCart();

          setFormData({
            fullName: "",
            mobile: "",
            email: "",
            city: "",
            address: "",
          });

          setTimeout(() => {
            setIsOrderPlaced(false);
          }, 5000);
        } else {
          alert("Failed to place order");
        }
      } catch (error) {
        console.error("Error:", error);
        alert("Failed to place order");
      }
    }
  };

  if (cart.length === 0) {
    return (
      <EmptyDiv>
        <h3>No Item in Cart</h3>
      </EmptyDiv>
    );
  }

  return (
    <>
      <Wrapper>
        <div className="container">
          <div className="cart_heading grid grid-five-column">
            <p>Item</p>
            <p className="cart-hide">Price</p>
            <p>Quantity</p>
            <p className="cart-hide">Subtotal</p>
            <p>Remove</p>
          </div>
          <hr />

          <div className="cart-item">
            {cart.map((curElem) => {
              return <CartItem key={curElem.id} {...curElem} />;
            })}
          </div>
          <div className="cart-two-button">
            <NavLink to="/products">
              <Button> continue Shopping </Button>
            </NavLink>
            <Button className="btn btn-clear" onClick={clearCart}>
              clear cart
            </Button>
          </div>

          {/* order total_amount */}
          <div className="order-total--amount">
            <div className="order-total--subdata">
              <div>
                <p>subtotal:</p>
                <p>
                  <FormatPrice price={total_price} />
                </p>
              </div>
              <div>
                <p>shipping fee:</p>
                <p>
                  <FormatPrice price={shipping_fee} />
                </p>
              </div>
              <hr />
              <div>
                <p>order total:</p>
                <p>
                  <FormatPrice price={shipping_fee + total_price} />
                </p>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </Wrapper>

      {/* Address Form */}
      <AddressFormContainer>
        <h2 className="form-title">Enter Your Delivery Address</h2>
        <form onSubmit={handleSubmit} className="address-form">
          {/* Full Name */}
          <div className="form-group">
            <label htmlFor="fullName">Full Name *</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Your Name"
            />
            {errors.fullName && (
              <span className="error">{errors.fullName}</span>
            )}
          </div>

          {/* Email */}
          <div className="form-group">
            <label htmlFor="email">Email *</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="youremail@example.com"
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>

          {/* Mobile Number */}
          <div className="form-group">
            <label htmlFor="mobile">Mobile *</label>
            <input
              type="text"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              placeholder="03XXXXXXXXX"
            />
            {errors.mobile && <span className="error">{errors.mobile}</span>}
          </div>

          {/* City */}
          <div className="form-group">
            <label htmlFor="city">City *</label>
            <select
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
            >
              <option value="">Select City</option>
              {cities.map((city, index) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
            </select>
            {errors.city && <span className="error">{errors.city}</span>}
          </div>

          {/* Address */}
          <div className="form-group">
            <label htmlFor="address">Address *</label>
            <textarea
              id="address"
              name="address"
              rows="4"
              value={formData.address}
              onChange={handleChange}
              placeholder="House #, Street, Area"
            ></textarea>
            {errors.address && <span className="error">{errors.address}</span>}
          </div>

          {/* Submit Button */}
          <button type="submit" className="submit-btn">
            Confirm Order
          </button>
        </form>
      </AddressFormContainer>

      {/* Success Message */}
      {isOrderPlaced && (
        <SuccessMessage>
          <IoCheckmarkCircleOutline className="success-icon" />
          <p>Your order has been successfully placed!</p>
        </SuccessMessage>
      )}
    </>
  );
};

const EmptyDiv = styled.div`
  display: grid;
  place-items: center;
  height: 50vh;

  h3 {
    font-size: 4.2rem;
    text-transform: capitalize;
    font-weight: 300;
  }
`;

const Wrapper = styled.section`
  padding: 9rem 0;

  .grid-four-column {
    grid-template-columns: repeat(4, 1fr);
  }

  .grid-five-column {
    grid-template-columns: repeat(4, 1fr) 0.3fr;
    text-align: center;
    align-items: center;
  }
  .cart-heading {
    text-align: center;
    text-transform: uppercase;
  }
  hr {
    margin-top: 1rem;
  }
  .cart-item {
    padding: 3.2rem 0;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
  }

  .cart-user--profile {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.2rem;
    margin-bottom: 5.4rem;

    img {
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
    }
    h2 {
      font-size: 2.4rem;
    }
  }
  .cart-user--name {
    text-transform: capitalize;
  }
  .cart-image--name {
    align-items: center;
    display: grid;
    gap: 1rem;
    grid-template-columns: 0.4fr 1fr;
    text-transform: capitalize;
    text-align: left;
    img {
      max-width: 5rem;
      height: 5rem;
      object-fit: contain;
      color: transparent;
    }

    .color-div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;

      .color-style {
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 50%;
      }
    }
  }

  .cart-two-button {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    .btn-clear {
      background-color: #e74c3c;
    }
  }

  .amount-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.4rem;
    font-size: 1.4rem;

    button {
      border: none;
      background-color: #fff;
      cursor: pointer;
    }

    .amount-style {
      font-size: 2.4rem;
      color: ${({ theme }) => theme.colors.btn};
    }
  }

  .remove_icon {
    font-size: 1.6rem;
    color: #e74c3c;
    cursor: pointer;
  }

  .order-total--amount {
    width: 100%;
    margin: 4.8rem 0;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    .order-total--subdata {
      border: 0.1rem solid #f0f0f0;
      display: flex;
      flex-direction: column;
      gap: 1.8rem;
      padding: 3.2rem;
    }
    div {
      display: flex;
      gap: 3.2rem;
      justify-content: space-between;
    }

    div:last-child {
      background-color: #fafafa;
    }

    div p:last-child {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.heading};
    }
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .grid-five-column {
      grid-template-columns: 1.5fr 1fr 0.5fr;
    }
    .cart-hide {
      display: none;
    }

    .cart-two-button {
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
      gap: 2.2rem;
    }

    .order-total--amount {
      width: 100%;
      text-transform: capitalize;
      justify-content: flex-start;
      align-items: flex-start;

      .order-total--subdata {
        width: 100%;
        border: 0.1rem solid #f0f0f0;
        display: flex;
        flex-direction: column;
        gap: 1.8rem;
        padding: 3.2rem;
      }
    }
  }
`;

const AddressFormContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .form-title {
    font-size: 2.8rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: ${({ theme }) => theme.colors.heading};
  }

  .address-form {
    display: grid;
    gap: 2.5rem;

    .form-group {
      display: grid;
      gap: 1.5rem;

      label {
        font-size: 2.2rem;
        margin-bottom: 0.7rem;
        color: ${({ theme }) => theme.colors.text};
      }

      input,
      select,
      textarea {
        width: 100%;
        padding: 1.8rem;
        font-size: 1.8rem;
        border-radius: 0.7rem;
        border: 1px solid #ccc;
        color: ${({ theme }) => theme.colors.text};
        transition: border-color 0.3s ease;

        &:focus {
          border-color: ${({ theme }) => theme.colors.primary};
          outline: none;
        }
      }

      .submit-btn {
        padding: 1.8rem;
        background-color: ${({ theme }) => theme.colors.primary};
        color: #fff;
        border: none;
        border-radius: 0.7rem;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: ${({ theme }) => theme.colors.primaryDark};
        }
      }

      .error {
        color: red;
        font-size: 1.8rem;
        margin-top: 0.5rem;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .form-title {
      font-size: 2.4rem;
    }

    input,
    select,
    textarea {
      font-size: 1.6rem;
      padding: 1.5rem;
    }
  }
`;

const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 0.5rem;
  padding: 1rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;

  .success-icon {
    animation: ${rotate} 1s linear infinite;
    font-size: 2rem;
  }

  p {
    font-size: 1.6rem;
    font-weight: bold;
  }
`;

export default Cart;
